<!-- =========================================================================================
    File Name: Calendar.vue
    Description: Calendar App
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div id="calendar-app">
    <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
        
    <div class="vx-card">
        <!-- <full-calendar class="w-full select-none" :events="calendarEventsView" lang="kr" @changeMonth="changeMonth" @dayClick ="openAddNewEvent" @eventClick="openEditEvent">

            <div slot="fc-header-left" class="flex flex-wrap sm:justify-start justify-center">
                <div v-for="(label, index) in calendarLabels" :key="index" class="flex items-center mr-4 mb-2">
                    <div class="h-3 w-3 inline-block rounded-full mr-2" :class="'bg-' + label.color"></div>
                    <span>{{ label.text }}</span>
                </div>
                <vs-button type="border" color="success" @click="overtimeStat">월간 통계</vs-button>
            </div>
            <div slot="fc-header-right" class="flex justify-end">
                <vs-button icon-pack="feather" icon="icon-plus" @click="promptAddNewEvent(new Date())">Add</vs-button>
            </div>
            
        </full-calendar> -->

        <div slot="fc-header-left" class="flex flex-wrap sm:justify-start justify-center">
          <div v-for="(label, index) in calendarLabels" :key="index" class="flex items-center mr-4 mb-2">
            <div class="h-3 w-3 inline-block rounded-full mr-2" :class="'bg-' + label.color"></div>
            <span>{{ label.text }}</span>
          </div>
        </div>
        <div>
          <vs-button type="border" color="success" @click="overtimeStat">월간 통계</vs-button>
        </div>
        <FullCalendar ref="fullCalendar" class="w-full select-none" :locale="locale"
                    defaultView="dayGridMonth"
                    :header="{
                      left: '',
                      center: 'title',
                      right: 'today prev,next',
                    }"
                    :googleCalendarApiKey="googleCalendarApiKey"
                    :dragScroll="false" :eventSources="eventSources"
                    :plugins="calendarPlugins" 
                    @dateClick="openAddNewEvent"
                    @datesRender="changeMonth" @eventClick="openEditEvent" />
    </div>

    <!-- ADD EVENT -->
    <div class="photo-fullsize">
      <vs-popup
          class="calendar-event-dialog"
          title="야근일지 작성"
          :active.sync="activePromptAddEvent">
          <div class="my-4">
              <small class="date-label">날짜 선택</small>
              <datepicker :format="datepickerFormat" name="start-date" v-model="start" :disabled="disabledFrom" :disabled-dates="disabledDates"></datepicker>
          </div>
          <v-select placeholder="작성자" label="name" v-model="writer_s"
            :options="writerOption" @input="changeType(type)" :clearable="false" :disabled="$store.state.AppActiveUser.level < 10"/>
          <div>
            <md-checkbox :disabled="timeoffFlg" v-model="deskFlg" >데스크</md-checkbox>
          </div>
          <div class="my-4">
            <small style="display: block" class="date-label">평일 근무시간</small>
            <date-picker :disabled="times2[1] != null || timeoffFlg" v-model="times1" type="time" range value-type="format" format="HH:mm" :minute-step="10" :clearable="true"/>
            <span v-if="times1[1]">{{worktime}} 시간</span>
          </div>
          <div class="my-4" >
            <small style="display: block" class="date-label">주말 / 공휴일 근무시간</small>
            <date-picker :disabled="times1[1] != null || timeoffFlg" v-model="times2" type="time" range value-type="format" format="HH:mm" :minute-step="10" :clearable="true"/>
            <span v-if="times2[1]">{{worktime}} 시간</span>
          </div>
          <div class="my-4" v-if="boardType == 2" >
            <md-checkbox v-model="timeoffFlg" >대체휴가</md-checkbox>
          </div>
          <!-- <v-select style="margin-left: 20px; width: 200px; display: inline-block" placeholder="야근타입 선택" v-model="type" 
            :clearable="false" @input="changeType" :options="typeSelect"/> -->
          <!-- <div class="flex items-center">
            <vs-input-number label="근무시간" v-model="worktime"/><p style="line-height: 38px; margin: 0 0 0 20px;">시간</p>
          </div> -->
          <vs-textarea rows="7" label="근무내용" v-model="contents1" />
          <!-- <vs-textarea rows="3" label="기타보고" v-model="contents2" /> -->
          <vs-button type="border" :disabled="!times1[1] && !times2[1] && !timeoffFlg" style="float: right; margin: 0 50px 0 0;" @click="addEvent">일지 저장</vs-button>
          <!-- <vs-button color="danger" type="border" style="float: right; margin: 0 50px 0 0;" @click="activePromptAddEvent=false">취소</vs-button> -->
      </vs-popup>
    </div>
    
    <!-- EDIT EVENT -->
    <div class="photo-fullsize">
      <vs-popup
          class="calendar-event-dialog"
          title="야근일지 수정"
          :active.sync="activePromptEditEvent">
          <div class="my-4">
              <small class="date-label">날짜 선택</small>
              <datepicker :format="datepickerFormat" name="start-date" v-model="start" :disabled="disabledFrom" :disabled-dates="disabledDates"></datepicker>
          </div>
          <v-select placeholder="작성자" label="name" v-model="writer_s"
            :options="writerOption" @input="changeType(type)" :clearable="false" :disabled="$store.state.AppActiveUser.level < 10"/>
          <div>
            <md-checkbox :disabled="timeoffFlg" v-model="deskFlg" >데스크</md-checkbox>
          </div>
          <div class="my-4">
            <small style="display: block" class="date-label">평일 근무시간</small>
            <date-picker :disabled="times2[1] != null || timeoffFlg" v-model="times1" type="time" range value-type="format" format="HH:mm" :minute-step="10" :clearable="true"/>
            <span v-if="times1[1]">{{worktime}} 시간</span>
          </div>
          <div class="my-4" >
            <small style="display: block" class="date-label">주말 / 공휴일 근무시간</small>
            <date-picker :disabled="times1[1] != null || timeoffFlg" v-model="times2" type="time" range value-type="format" format="HH:mm" :minute-step="10" :clearable="true"/>
            <span v-if="times2[1]">{{worktime}} 시간</span>
          </div>
          <div class="my-4" v-if="boardType == 2" >
            <md-checkbox v-model="timeoffFlg" >대체휴가</md-checkbox>
          </div>
          <vs-textarea rows="5" label="근무내용" v-model="contents1" />
          <!-- <vs-textarea rows="3" label="기타보고" v-model="contents2" /> -->
          <vs-button v-if="$store.state.AppActiveUser.level > 9 ||( $store.state.AppActiveUser.id == writer && moment(start) > moment().subtract(7, 'day'))" type="border" style="float: right; margin: 0 50px 0 0;" @click="editEvent">일지 저장</vs-button>
          <vs-button v-if="$store.state.AppActiveUser.level > 9" color="danger" type="border" style="float: right; margin: 0 50px 0 0;" @click="removeEvent">삭제</vs-button>

      </vs-popup>
    </div>
    <!-- Start: 통계 팝업 -->
    <div class="photo-fullsize">
      <vs-popup ref="popup-photo" classContent="popup-photo" title="" :active.sync="statModalActive">
        
        <vs-table ref="table" :data="calendarStat">
          <template slot="thead">
            <vs-th>이름</vs-th>
            <vs-th>평일</vs-th>
            <vs-th>휴일</vs-th>
            <vs-th>평일총근무시간</vs-th>
            <vs-th>주말총근무시간</vs-th>
            <vs-th v-if="boardType == 2">대체휴가</vs-th>
          </template>

            <template slot-scope="{data}">
              <tbody>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>{{ tr.name }}</vs-td>
                  <vs-td>{{ tr.overtimeDay }}</vs-td>
                  <vs-td>{{ tr.overtimeFree }}</vs-td>
                  <vs-td>{{ tr.totalTime2.toFixed(2) }}</vs-td>
                  <vs-td>{{ tr.totalTime.toFixed(2) }}</vs-td>
                  <vs-td v-if="boardType == 2">{{ tr.timeoff }}</vs-td>
                </vs-tr>
              </tbody>
            </template>
        </vs-table>
      </vs-popup>
    </div>
    <!-- End: 통계 팝업 -->

    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import DatePicker from 'vue2-datepicker';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import moment from 'moment';
import { setTimeout } from 'timers';

import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import koLocale from '@fullcalendar/core/locales/ko';

export default {
  data() {
    return {
      boardType: 1,
      calendarStat: [],
      currentMonth: '',
      overtime_writer: '',
      overtime_writer0: '',
      overtime_writer1: '',
      overtime_writer2: '',
      overtime_writer3: '',
      timeoff: '',
      timeoffFlg: null,
      writer_s: {},
      writerOption: [],
      typeSelect: [
        '데스크', 
        '데스크(평일)', 
        '평일(당직)',
        '주말공(재택)',
        '주말공(현장)',
      ],
      type: null,
      start: '',
      end: '',
      worktime: 0,
      times1: [null,null],//['18:00', '20:00'],
      times2: [null,null],//['10:00', '15:00'],
      deskFlg: false,
      contents1: '',
      contents2: '',
      id: 0,
      writer: 0,
      disabledFrom: false,
      disabledDates: {
        to: new Date(moment().subtract(7, 'day'))
      },
      labelLocal: 'none',
      settings: {
          maxScrollbarLength: 60,
          wheelSpeed: 0.30,
      },
      activePromptAddEvent: false,
      activePromptEditEvent: false,
      statModalActive: false,
      calendarEvents: [],
      calendarEventsView: [],
      datepickerFormat: "yyyy-MM-dd",
      moment: moment,
      calendarPlugins: [ dayGridPlugin, interactionPlugin, googleCalendarPlugin ],
      googleCalendarApiKey: 'AIzaSyBC448C8hsdo3l1z_9Gq2S9lQcKsphhwZk',
      eventSources: [
        {
          events: []
        },
        {
          events: [],
        },
        {
          events: [],
        },
        {
          googleCalendarId: 'ko.south_korea#holiday@group.v.calendar.google.com',
          editable: false,
          textColor: 'black',
          className: 'gcal-event',
        }
      ],
      locale: koLocale,
    }
  },
  components: {
      // 'full-calendar': require('vue-fullcalendar'),
      FullCalendar,
      Datepicker,
      DatePicker,
      VuePerfectScrollbar
  },
  beforeRouteLeave (to, from, next) {
      this.activePromptAddEvent = false;
      this.activePromptEditEvent = false;
      setTimeout(() => { next(); }, 100);
  },
  computed: {
    calendarLabels() {
      if(this.boardType == 2){
      return this.$store.state.calendarLabels.concat({text: "대체휴가", value: "대체휴가", color: "secondary"})
      }
      return this.$store.state.calendarLabels
    },
    labelColor() {
      return (label) => {
        if (label == "데스크") return "#28C76F"
        else if (label == "데스크(평일)") return "#28975F"
        else if (label == "평일당직") return "#FF9F43"
        else if (label == "주말공재") return "#EA5455"
        else if (label == "주말공현") return "#7367F0"
        else if (label == "대체휴가") return "#FFE044"
      }
    },
  },
  watch:{
    times1: function(data){
      if(data[0] && data[1]){
        const today = moment().format('YYYY-MM-DD ');
        // console.log(data);
        this.worktime = Math.round(moment(today + data[1]).diff(moment(today + data[0]), 'hours', true) * 100)/100;
      }
    },
    times2: function(data){
      if(data[0] && data[1]){
        const today = moment().format('YYYY-MM-DD ');
        // console.log(data);
        this.worktime = Math.round(moment(today + data[1]).diff(moment(today + data[0]), 'hours', true) * 100)/100;
      }
    },
    '$route.query': function() {
      this.boardType = this.$route.query.boardType
      this.getEventList(this.currentMonth);
    }
  },
  methods: {
    changeType(val){
      console.log(val, this.writer_s);
      switch (val) {
        case '데스크':
          this.overtime_writer = this.writer_s.name;
          this.overtime_writer0 = '';
          this.overtime_writer1 = '';
          this.overtime_writer2 = '';
          this.overtime_writer3 = '';
          break;
        case '데스크(평일)':
          this.overtime_writer = '';
          this.overtime_writer0 = this.writer_s.name;
          this.overtime_writer1 = '';
          this.overtime_writer2 = '';
          this.overtime_writer3 = '';
          break;
      
        case '평일(당직)':
          this.overtime_writer = '';
          this.overtime_writer0 = '';
          this.overtime_writer1 = this.writer_s.name;
          this.overtime_writer2 = '';
          this.overtime_writer3 = '';
          break;
          
        case '주말공(재택)':
          this.overtime_writer = '';
          this.overtime_writer0 = '';
          this.overtime_writer1 = '';
          this.overtime_writer2 = this.writer_s.name;
          this.overtime_writer3 = '';
          break;
          
        case '주말공(현장)':
          this.overtime_writer = '';
          this.overtime_writer0 = '';
          this.overtime_writer1 = '';
          this.overtime_writer2 = '';
          this.overtime_writer3 = this.writer_s.name;
          break;

        default:
          this.overtime_writer = '';
          this.overtime_writer0 = '';
          this.overtime_writer1 = this.writer_s.name;
          this.overtime_writer2 = '';
          this.overtime_writer3 = '';
          break;
      }
    },
    overtimeStat(){
      // let overtime = [];
      // let overtime1 = [];
      // let overtime2 = [];
      // let overtime3 = [];
      this.calendarStat = [];
      for(const day of this.calendarEvents){
        for(const name of day.overtime_writer.replace(/\s?(\(|\[).*(\)|\])/, '').trim().split(/, {2}| {2}|, |,| /)){
          if(name){
            const tmpIdx = this.calendarStat.findIndex(e => e.name == name);
            if(tmpIdx > -1){ this.calendarStat[tmpIdx].overtimeFree++; this.calendarStat[tmpIdx].totalTime += day.worktime; }
            else{ this.calendarStat.push({ name, overtimeFree: 1, overtimeDay: 0, totalTime: day.worktime, totalTime2: 0, timeoff: 0})}
          }
        }
        for(const name of day.overtime_writer0.replace(/\s?(\(|\[).*(\)|\])/, '').trim().split(/, {2}| {2}|, |,| /)){
          if(name){
            const tmpIdx = this.calendarStat.findIndex(e => e.name == name);
            if(tmpIdx > -1){ this.calendarStat[tmpIdx].overtimeDay++; this.calendarStat[tmpIdx].totalTime2 += day.worktime; }
            else{ this.calendarStat.push({ name, overtimeFree: 0, overtimeDay: 1, totalTime: 0, totalTime2: day.worktime, timeoff: 0})}
          }
        }
        for(const name of day.overtime_writer1.replace(/\s?(\(|\[).*(\)|\])/, '').trim().split(/, {2}| {2}|, |,| /)){
          if(name){
            const tmpIdx = this.calendarStat.findIndex(e => e.name == name);
            if(tmpIdx > -1){ this.calendarStat[tmpIdx].overtimeDay++; this.calendarStat[tmpIdx].totalTime2 += day.worktime; }
            else{ this.calendarStat.push({ name, overtimeFree: 0, overtimeDay: 1, totalTime: 0, totalTime2: day.worktime, timeoff: 0})}
          }
        }
        for(const name of day.overtime_writer2.replace(/\s?(\(|\[).*(\)|\])/, '').trim().split(/, {2}| {2}|, |,| /)){
          if(name){
            const tmpIdx = this.calendarStat.findIndex(e => e.name == name);
            if(tmpIdx > -1){ this.calendarStat[tmpIdx].overtimeFree++; this.calendarStat[tmpIdx].totalTime += day.worktime; }
            else{ this.calendarStat.push({ name, overtimeFree: 1, overtimeDay: 0, totalTime: day.worktime, totalTime2: 0, timeoff: 0})}
          }
        }
        for(const name of day.overtime_writer3.replace(/\s?(\(|\[).*(\)|\])/, '').trim().split(/, {2}| {2}|, |,| /)){
          if(name){
            const tmpIdx = this.calendarStat.findIndex(e => e.name == name);
            if(tmpIdx > -1){ this.calendarStat[tmpIdx].overtimeFree++; this.calendarStat[tmpIdx].totalTime += day.worktime; }
            else{ this.calendarStat.push({ name, overtimeFree: 1, overtimeDay: 0, totalTime: day.worktime, totalTime2: 0, timeoff: 0})}
          }
        }
        if(day.timeoff){
          const tmpIdx = this.calendarStat.findIndex(e => e.name == day.timeoff);
          if(tmpIdx > -1){ this.calendarStat[tmpIdx].timeoff++; }
          else{ this.calendarStat.push({ name: day.timeoff, overtimeFree: 0, overtimeDay: 0, totalTime: 0, timeoff: 1})}
        }
        // if(day.overtime_writer) { overtime = overtime.concat(day.overtime_writer.trim().split(/, {2}| {2}|, |,| /)); }
        // if(day.overtime_writer1) { overtime1 = overtime1.concat(day.overtime_writer1.trim().split(/, {2}| {2}|, |,| /)); }
        // if(day.overtime_writer2) { overtime2 = overtime2.concat(day.overtime_writer2.trim().split(/, {2}| {2}|, |,| /)); }
        // if(day.overtime_writer3) { overtime3 = overtime3.concat(day.overtime_writer3.trim().split(/, {2}| {2}|, |,| /)); }
      }
      // console.log(overtime, overtime1, overtime2, overtime3)
      this.statModalActive = true;
      console.log(this.calendarStat)
    },
    addEvent() {
      this.overtime_writer = this.overtime_writer0 = this.overtime_writer1 
        = this.overtime_writer2 = this.overtime_writer3 = this.timeoff = "";
      let startTime, endTime;
      if(this.timeoffFlg){
        this.timeoff = this.writer_s.name;
      }else if(this.times1[1]){
        startTime = this.times1[0];
        endTime = this.times1[1];
        if(this.deskFlg){
          this.overtime_writer0 = this.writer_s.name;
        }else{
          this.overtime_writer1 = this.writer_s.name;
        }
      }else if(this.times2[1]){
        startTime = this.times2[0];
        endTime = this.times2[1];
        if(this.deskFlg){
          this.overtime_writer = this.writer_s.name;
        }else{
          this.overtime_writer2 = this.writer_s.name;
        }
      }
      const obj = { id: this.id, title: moment(this.start).format('YYYY-MM-DD'), writer: this.writer_s.idx, overtime_writer: this.overtime_writer, overtime_writer0: this.overtime_writer0, 
                  overtime_writer1: this.overtime_writer1, overtime_writer2: this.overtime_writer2, overtime_writer3: this.overtime_writer3, timeoff: this.timeoff,
                  contents1: this.contents1, contents2: this.contents2, worktime: this.worktime, startTime: startTime, endTime: endTime,
                  boardType: this.boardType};
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/board/setOverTime', obj)
        .then(function () {
          thisIns.activePromptAddEvent = false;
          thisIns.$vs.notify({
            title:'성공',
            text: '야근일지 저장 성공',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.getEventList(thisIns.currentMonth);
          thisIns.$vs.loading.close();
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });
    },
    clearFields() {
      this.title = this.overtime_writer = this.overtime_writer0 = this.overtime_writer1 = this.overtime_writer2 = this.overtime_writer3
           = this.contents1 = this.contents2 = "";
      this.id = 0;
      this.times1 = [null,null];
      this.times2 = [null,null];
      this.deskFlg = false;
      this.labelLocal = "none";
    },
    addNewEventDialog(date) {
      this.clearFields();
      this.start = date;
      this.end = date;
      this.worktime = 0;
      this.type = null;
      this.activePromptAddEvent = true;
    },
    openAddNewEvent(event) {
      let date = event.date;
      this.writer_s = this.writerOption.find(e => e.idx == this.$store.state.AppActiveUser.id) || {};
      // console.log(moment(date).format('YYYY-MM-DD'), this.calendarEvents)
      // const tmpEvent = this.calendarEvents.find(e => e.start.match(moment(date).format('YYYY-MM-DD')));
      // if(tmpEvent){
      //   this.openEditEvent(tmpEvent)
      // }else{
        // this.disabledFrom = true;
        // this.addNewEventDialog(date);
      // }
      this.disabledFrom = true;
      // if(this.$store.state.AppActiveUser.level >= 10 || moment(date) > moment().subtract(7, 'day')){
        this.addNewEventDialog(date);
      // }else{
      //   this.addNewEventDialog(moment().format('YYYY-MM-DD'));
      // }
    },
    promptAddNewEvent(date) {
      this.disabledFrom = false;
      this.addNewEventDialog(date);
    },
    openEditEvent(event) {
      if(event.event.id){
        const e = this.calendarEvents.find(e => e.id == event.event.id); //this.$store.getters['calendar/eventById'](event.id)
        this.id = e.id;
        this.disabledFrom = true;
        this.activePromptEditEvent = true;
        this.writer = e.writer;
        this.worktime = e.worktime;
        if(e.overtime_writer){
          this.writer_s = this.writerOption.find(e2 => e2.name == e.overtime_writer.replace(/\s?\(.*\)/, '').replace(/^_/, ''));
          this.deskFlg = true;
          this.timeoffFlg = false;
          this.times1 = [null, null];
          this.times2 = [e.startTime, e.endTime];
        }else if(e.overtime_writer0){
          this.writer_s = this.writerOption.find(e2 => e2.name == e.overtime_writer0.replace(/\s?\(.*\)/, '').replace(/^_/, ''));
          this.deskFlg = true;
          this.timeoffFlg = false;
          this.times2 = [null, null];
          this.times1 = [e.startTime, e.endTime];
        }else if(e.overtime_writer1){
          this.writer_s = this.writerOption.find(e2 => e2.name == e.overtime_writer1.replace(/\s?\(.*\)/, ''));
          this.deskFlg = false;
          this.timeoffFlg = false;
          this.times2 = [null, null];
          this.times1 = [e.startTime, e.endTime];
        }else if(e.overtime_writer2){
          this.writer_s = this.writerOption.find(e2 => e2.name == e.overtime_writer2.replace(/\s?\(.*\)/, ''));
          this.deskFlg = false;
          this.timeoffFlg = false;
          this.times1 = [null, null];
          this.times2 = [e.startTime, e.endTime];
        }else if(e.overtime_writer3){
          this.writer_s = this.writerOption.find(e2 => e2.name == e.overtime_writer3.replace(/\s?\(.*\)/, ''));
          this.deskFlg = false;
          this.timeoffFlg = false;
          this.times1 = [null, null];
          this.times2 = [e.startTime, e.endTime];
        }else if(e.timeoff){
          this.writer_s = this.writerOption.find(e2 => e2.name == e.timeoff.replace(/\s?\(.*\)/, ''));
          this.deskFlg = false;
          this.timeoffFlg = true;
          this.times1 = [null, null];
          this.times2 = [null, null];
        }else{
          this.writer_s = this.writerOption.find(e2 => e2.name == e.overtime_writer1.replace(/\s?\(.*\)/, ''));
          this.deskFlg = false;
          this.times2 = [null, null];
          this.times1 = [e.startTime, e.endTime];
        }
        this.start = e.start;
        this.end = e.end;
        this.contents1 = e.contents1;
        this.contents2 = e.contents2;
        this.type = null;
      }
    },
    changeMonth(event){
      // console.log('event: ', moment(event.view.currentStart).format('YYYY-MM'));
      this.currentMonth = moment(event.view.currentStart).format('YYYY-MM');
      this.getEventList(this.currentMonth);
    },
    editEvent() {
      this.overtime_writer = this.overtime_writer0 = this.overtime_writer1 
        = this.overtime_writer2 = this.overtime_writer3 = this.timeoff = "";
      let startTime, endTime;
      if(this.timeoffFlg){
        this.timeoff = this.writer_s.name;
      }else if(this.times1[1]){
        startTime = this.times1[0];
        endTime = this.times1[1];
        if(this.deskFlg){
          this.overtime_writer0 = this.writer_s.name;
        }else{
          this.overtime_writer1 = this.writer_s.name;
        }
      }else if(this.times2[1]){
        startTime = this.times2[0];
        endTime = this.times2[1];
        if(this.deskFlg){
          this.overtime_writer = this.writer_s.name;
        }else{
          this.overtime_writer2 = this.writer_s.name;
        }
      }
      const obj = { id: this.id, title: moment(this.start).format('YYYY-MM-DD'), writer: this.writer_s.idx, overtime_writer: this.overtime_writer, overtime_writer0: this.overtime_writer0, 
                  overtime_writer1: this.overtime_writer1, overtime_writer2: this.overtime_writer2, overtime_writer3: this.overtime_writer3, timeoff: this.timeoff,
                  contents1: this.contents1, contents2: this.contents2, worktime: this.worktime, startTime: startTime, endTime: endTime};
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/board/editOverTime', obj)
        .then(function () {
          thisIns.activePromptEditEvent = false;
          thisIns.$vs.notify({
            title:'성공',
            text: '야근일지 수정 성공',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.getEventList(thisIns.currentMonth);
          thisIns.$vs.loading.close();
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });
    },
    removeEvent() {
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/board/deleteOverTime', {id: this.id})
        .then(function () {
          thisIns.activePromptEditEvent = false;
          thisIns.$vs.notify({
            title:'성공',
            text: '야근일지 삭제 성공',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.getEventList(thisIns.currentMonth);
          thisIns.$vs.loading.close();
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });
    },
    getEventList(currentMonth){
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/board/getOverTimeList', {data: currentMonth, boardType: thisIns.boardType})
        .then(function (response) {
          // console.log(response);
          thisIns.calendarEvents = response.data;
          thisIns.calendarEventsView = [];
          return thisIns.$http.post('/api/board/getOverTimeList', {data: moment(currentMonth + '-01').add(-1, 'month').format('YYYY-MM'), boardType: thisIns.boardType})
        }).then(function (response) {
          let showEvents = response.data.concat(thisIns.calendarEvents);
          let overtimeArray= [[],[],[],[],[], []];
          for(var event of showEvents){
            if(event.overtime_writer){
              overtimeArray[0].push({id: event.id, title: '_' + event.overtime_writer, 
                                        start: event.start, end: event.end, 
                                        backgroundColor: thisIns.labelColor('데스크'), borderColor: thisIns.labelColor('데스크'), 
                                        textColor: 'black', cssClass: 'event-' + thisIns.labelColor('데스크'), label: '데스크'});
            }
            if(event.overtime_writer0){
              overtimeArray[1].push({id: event.id, title: '_' + event.overtime_writer0, 
                                        start: event.start, end: event.end, 
                                        backgroundColor: thisIns.labelColor('데스크(평일)'), borderColor: thisIns.labelColor('데스크(평일)'), 
                                        textColor: 'black', cssClass: 'event-' + thisIns.labelColor('데스크(평일)'), label: '데스크(평일)'});
            }
            if(event.overtime_writer1){
              overtimeArray[2].push({id: event.id, title: event.overtime_writer1, 
                                        start: event.start, end: event.end, 
                                        backgroundColor: thisIns.labelColor('평일당직'), borderColor: thisIns.labelColor('평일당직'), 
                                        textColor: 'black', cssClass: 'event-' + thisIns.labelColor('평일당직'), label: '평일당직'});
            }
            if(event.overtime_writer2){
              overtimeArray[3].push({id: event.id, title: event.overtime_writer2, 
                                        start: event.start, end: event.end, 
                                        backgroundColor: thisIns.labelColor('주말공재'), borderColor: thisIns.labelColor('주말공재'), 
                                        textColor: 'black', cssClass: 'event-' + thisIns.labelColor('주말공재'), label: '주말공재'});
            }
            if(event.overtime_writer3){
              overtimeArray[4].push({id: event.id, title: event.overtime_writer3, 
                                        start: event.start, end: event.end, 
                                        backgroundColor: thisIns.labelColor('주말공현'), borderColor: thisIns.labelColor('주말공현'), 
                                        textColor: 'black', cssClass: 'event-' + thisIns.labelColor('주말공현'), label: '주말공현'});
            }
            if(event.timeoff){
              overtimeArray[5].push({id: event.id, title: event.timeoff, 
                                        start: event.start, end: event.end, 
                                        backgroundColor: thisIns.labelColor('대체휴가'), borderColor: thisIns.labelColor('대체휴가'), 
                                        textColor: 'black', cssClass: 'event-' + thisIns.labelColor('대체휴가'), label: '대체휴가'});
            }
          }
          thisIns.calendarEventsView = overtimeArray[0].concat(overtimeArray[1]).concat(overtimeArray[2]).concat(overtimeArray[3]).concat(overtimeArray[4]).concat(overtimeArray[5]);
          thisIns.eventSources[0].events = thisIns.calendarEventsView;
          setTimeout(() => {
            console.log(thisIns.$refs.fullCalendar.getApi().renderableEventStore);
            let calEvents = thisIns.$refs.fullCalendar.getApi().renderableEventStore.defs;
            let calEventsArray = [];
            // let calEventsArray2 = [];
            for(const key of Object.keys(calEvents)){
              if(calEvents[key].url && ['어버이날', '스승의날', '국군의날', '크리스마스 이브', '섣달 그믐날', '식목일'].findIndex(e => e == calEvents[key].title) == -1){
                calEventsArray.push({
                  start: moment(calEvents[key].publicId.substr(0,8)).format('YYYY-MM-DD'),
                  end: moment(calEvents[key].publicId.substr(0,8)).format('YYYY-MM-DD'),
                  rendering: 'background',
                  backgroundColor: 'rgba(255,180,180,1)',
                });
                // calEventsArray2.push({
                //   start: moment(calEvents[key].publicId.substr(0,8)).format('YYYY-MM-DD'),
                //   end: moment(calEvents[key].publicId.substr(0,8)).format('YYYY-MM-DD'),
                //   title: calEvents[key].title,
                //   textColor: 'black'
                // });
              }
            }
            if(currentMonth.substr(5,2) == "05"){
              calEventsArray.push({
                    start: moment(currentMonth + '-01').format('YYYY-MM-DD'),
                    end: moment(currentMonth + '-01').format('YYYY-MM-DD'),
                    rendering: 'background',
                    backgroundColor: 'rgba(255,180,180,1)',
              });
            }
            thisIns.eventSources[1].events = calEventsArray;
            // thisIns.eventSources[2].events = calEventsArray2;
            // console.log(thisIns.eventSources[1].events)
          }, 500);
          thisIns.$vs.loading.close();
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });        
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
    created(){
      this.boardType = this.$route.query.boardType
      const thisIns = this;
      this.currentMonth = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2);
      // console.log(this.currentMonth);
      // this.getEventList(this.currentMonth);
      if(!this.$store.state.writerAll){
        this.$http.post('/api/getVarList/writer', {})
          .then(function (response) {
            console.log(response)
            thisIns.writerOption = response.data;
            thisIns.writerOption = thisIns.writerOption.filter(e => moment(e.entry_date) > moment('2000-01-01'));
            for(let i in thisIns.writerOption){
              const resetDate = moment(thisIns.writerOption[i].entry_date).year(moment().format('YYYY'))
              if(moment() > resetDate){
                thisIns.writerOption[i].resetDate = resetDate.format('YYYY-MM-DD')
              }else{
                thisIns.writerOption[i].resetDate = resetDate.subtract(1,'year').format('YYYY-MM-DD')
              }
              thisIns.writerOption[i].entry_date = moment(thisIns.writerOption[i].entry_date).format('YYYY-MM-DD')
              // console.log(thisIns.writerOption[i].entry_date)
            }
            console.log(thisIns.writerOption)
            thisIns.$store.dispatch('SetWriterAll', {writerAll: thisIns.writerOption});
            console.log(thisIns.writerOption)
            thisIns.writer_s = thisIns.writerOption.find(e => e.idx == thisIns.$store.state.AppActiveUser.id) || {};
            console.log(thisIns.writerOption, thisIns.writer_s)
          })
          .catch(function (error) {
            var errorText = error.toString();
            if(errorText.includes('401')){
              alert('로그인 해주세요!')
              thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
            }
            thisIns.$vs.notify({
              title:'Error',
              text: error,
              color:'danger',
              iconPack: 'feather',
              icon:'icon-alert-circle'})
            thisIns.$vs.loading.close(); 
          });  
      }else{
        this.writerOption = this.$store.state.writerAll;
        this.writer_s = this.writerOption.find(e => e.idx == this.$store.state.AppActiveUser.id) || {};
      }
    }
  },
  created(){
    this.created()
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/apps/calendar.scss";

@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
#calendar-app{
  .fc-sat{
    background-color: rgba(180, 180, 255, 0.3);
  }
  .fc-sun{
    background-color: rgba(255, 180, 180, 0.3);
  }
  .gcal-event{
    pointer-events: none;
    display: none;
  }
}
</style> 